import request from '@/utils/request';

/**
 * 查询所有用户预订
 * @param {Object} data
 */
export function getAllList(data) {
  return request({
    url: '/canteen/entertain/getAllList',
    method: 'post',
    data
  });
}

/**
 * 订餐分配
 * @param {Object} data
 */
export function setEntertain(data) {
  return request({
    url: '/canteen/entertain/setEntertain',
    method: 'post',
    data
  });
}

/**
 * 状态修改
 * @param {Object} data
 */
export function updateStatus(data) {
  return request({
    url: '/canteen/entertain/updateStatus',
    method: 'post',
    data
  });
}

/**
 * 扣费或批量扣费
 * @param {Object} data
 */
export function updateCostPrice(data) {
  return request({
    url: '/canteen/entertain/updateCostPrice',
    method: 'post',
    data
  });
}

export function delEntertain(data) {
  return request({
    url: '/canteen/entertain/deleteEntertain',
    method: 'post',
    data
  });
}
