import {delEntertain} from "@/api/canteen/entertain";

export default {
    data() {
        return {
            deleteloading:{
                loading:false,
                url: '/canteen/entertain/deleteEntertain',
                method: 'post'
            }
        };
    },
    methods: {
        onDelete(row){
            this.$confirm('此操作将删除该预约, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const data = {id:row.id}
                delEntertain(data).then(res=>{
                    // console.log(res)
                    if (res.code === 200){
                        this.$message({
                            message: '删除成功',
                            type: 'success'
                        });
                        this.$refs.page.onSearch()
                    }
                })
            }).catch(() => {});
        },
    }
};
